import supplierSelect from './components/supplierSelect'
import moneySectionInput from './components/money-section-input'

// 查询form
export const dataForm = (content) => {
  const startOptions = {
    disabledDate (time) {
      return time.getTime() <= new Date('1989-12-30')
    }
  }
  return [
    {
      label: '供应商名称',
      prop: 'gysId',
      attrs: {
        placeholder: '请选择'
      },
      tag: supplierSelect,
      on: {
        change: (keyId, data) => {
          content.changeQueryGys(data)
        }
      }
    },
    {
      label: '产值变更日期区间',
      prop: 'changeTime',
      attrs: {
        placeholder: '请选择',
        type: 'daterange',
        rangeSeparator: '-',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        valueFormat: 'yyyy-MM-dd',
        pickerOptions: startOptions,
        clearable: false
      },
      tag: 'el-date-picker'
    },
    {
      label: '累计产值变化金额区间(元)',
      prop: 'intervalValue',
      attrs: {
        placeholder: '请输入',
        value1: content.queryParas.intervalValue[0],
        value2: content.queryParas.intervalValue[1],
        type: 'Y'
      },
      tag: moneySectionInput,
      on: {
        firstInput (data) {
          content.AmountArrChange(0, data)
        },
        secondInput (data) {
          content.AmountArrChange(1, data)
        }
      }
    }
  ]
}
// 表格form
export const dataTable = () => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '60px'
    },
    {
      label: '供应商名称',
      prop: 'gysName',
      'show-overflow-tooltip': true,
      align: 'left',
      minWidth: '110px'
    },
    {
      label: '产值变更日期区间',
      prop: 'changeTime',
      'show-overflow-tooltip': true,
      align: 'center'
    },
    {
      label: '累计产值变化金额（元）',
      prop: 'valueChangeStr',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.valueChangeStr ? parseFloat(row.valueChangeStr).toLocaleString('en-US', { minimumFractionDigits: 2 }) : '0.00')
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '150px',
      fixed: 'right'
    }
  ]
}
