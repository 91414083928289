<template>
  <div class="assetChangeQuery">
    <!-- 查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '120px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData"
    ></base-form>
    <el-row class="main-page-table__header flex-start">
      <span class="summation">产值变化金额合计（元）：{{ moneyTotal }}</span>
    </el-row>
    <!-- 表格 -->

    <base-table
      class="main-page-table with-add"
      :columns="columns"
      :showPage="true"
      :loadCount="loadCount"
      :queryParas.sync="queryParas"
      :api="api"
      :getApi="'outPutPage'"
      :tableAttrs="{
        data: tableData,
        stripe: true
      }"
      :dataSource.sync="tableData"
      ref="tableData"
      :webPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <!-- 产值变更日期区间 -->
      <template slot="changeTime">
        {{ queryParas.changeStartTime }} 至 {{ queryParas.changeEndTime }}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          @click="viewDetails(scope.row)"
          content="查看明细"
          icon="iconfont iconchakan"
        />
      </template>
    </base-table>
  </div>
</template>
<script>
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button'
import { dataForm, dataTable } from './config'
// import { supplierApi } from '@/api/companyApi'
import { outputApi } from '@/api/assetApi'
export default {
  name: 'assetChangeQuery',
  components: { BaseForm, BaseTable, IconButton },
  data () {
    return {
      dropOptions: [],
      queryParas: {
        pageIndex: 1,
        pageSize: 10,
        changeTime: [],
        intervalValue: ['', ''],
        gysId: ''
      },
      loadCount: 0,
      tableData: [],
      moneyTotal: ''
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return outputApi
    }
  },
  watch: {
    'queryParas.changeTime': {
      handler (val) {
        if (val && val.length > 0) {
          this.queryParas.changeStartTime = val[0]
          this.queryParas.changeEndTime = val[1]
        } else {
          this.queryParas.changeStartTime = ''
          this.queryParas.changeEndTime = ''
        }
      },
      deep: true
    },
    'queryParas.intervalValue': {
      handler (val) {
        if (val && val.length > 0) {
          this.queryParas.intervalStartValue = val[0]
          this.queryParas.intervalEndValue = val[1]
        } else {
          this.queryParas.intervalStartValue = ''
          this.queryParas.intervalEndValue = ''
        }
      },
      deep: true
    }
  },
  created () {
    // 申请主体下拉
    // supplierApi.drop().then((res) => {
    //   this.dropOptions = res.data
    // })
  },
  mounted () {
    this.getTime()
    this.handleFilter()
  },
  methods: {
    // 查询产值变化总计数据
    outPutTotal () {
      this.api.outPutTotal(this.queryParas).then(res => {
        this.moneyTotal = res.data
          ? res.data.toLocaleString('en-US', { minimumFractionDigits: 2 })
          : '0.00'
      })
    },
    // 默认当前时间
    getTime () {
      const myDate = new Date()
      const myYear = myDate.getFullYear()
      const myMonth = myDate.getMonth() + 1
      const myToday = myDate.getDate()
      const date =
        myYear +
        '-' +
        (myMonth > 9 ? myMonth : '0' + myMonth) +
        '-' +
        (myToday > 9 ? myToday : '0' + myToday)
      this.queryParas.changeTime.push(date)
      this.queryParas.changeTime.push(date)
    },
    // 查询增加选项的name
    changeQueryGys (data) {
      if (data) {
        this.$set(this.queryParas, 'gysName', data.enterpriseName)
      } else {
        this.$set(this.queryParas, 'gysName', '')
      }
    },
    // 金额区间选取
    AmountArrChange (index, value) {
      this.$set(this.queryParas.intervalValue, index, value)
    },
    // 查询
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
      this.$nextTick(() => {
        this.outPutTotal()
      })
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageIndex: 1,
        pageSize: 10,
        changeTime: [],
        intervalValue: ['', '']
      }
      this.getTime()
    },
    // 查看明细
    viewDetails (data) {
      this.$router.push({
        path: '/assetsManage/assetChangeDetail',
        query: { gysId: data.gysId, changeTime: this.queryParas.changeTime }
      })
    }
  }
}
</script>
