// 供应商名称下拉
<template>
  <base-select
      :options="options"
      :selectedField="selectedField"
      :filterable="true"
      v-bind="$attrs"
      v-on="$listeners"
      @change="change"
     ></base-select>
</template>

<script>
import { supplierApi } from '@/api/companyApi'
import BaseSelect from '@/components/common/base-select/base-select.vue'

export default {
  components: { BaseSelect },
  data () {
    return {
      options: [],
      allData: [],
      selectedField: ['keyId', 'enterpriseName']
    }
  },
  mounted () {
    this.filterMethod()
  },
  watch: {
    '$attrs.value': {
      handler (val) {
        if (!val) {
          this.filterMethod(val)
        }
      },
      deep: true
    }
  },
  methods: {
    async filterMethod (label) {
      // let arr = []
      if (this.allData.length === 0) {
        const res = await supplierApi.drop()
        this.options = res.data
      }
      // arr = this.allData.filter(item => item.enterpriseName.includes(label))
      // if (!label) { arr = this.allData.slice(0, 20) }
      // this.options = arr.length > 20 ? arr.slice(0, 20) : arr
    },
    change (data) {
      const obj = this.options.filter((item) => item.keyId === data)
      this.$emit('change', data, obj)
    }
  }
}
</script>
